import React from "react";

import LazyLoad from 'react-lazyload';
import SEO from "../components/seo";
import Layout from "../components/layout";
import Heroimage from "../components/HeroImageHome";
import VideoContent from "../components/VideoContent";
import AboutContent from "../components/AboutContent";
import TestimonialContent from "../components/TestimonialContent";


const IndexPage = ({data}) => {

    return(
        <Layout>
            <SEO bodyClass="main-page" title="Home" keywords={[`HIV`, `South Africa`, `HIV counseling`, `HIV test`, `HIV testing`, `Youth Health Counseling`, `eHealth`, `Digital Counseling`, `MSF`, `Aviro`, `HIV counseling`, `AIDS counseling`]} />

            <div className="hero">
                <Heroimage src="hero-home.jpg" />
            </div>

            <div className="container">

                <section className="section text-intro">
                    <h1 className="title">HIV Digital Counseling</h1>
                    <h2 className="subtitle">Designed with <strong>youth</strong> for <strong>youth</strong></h2>
                </section>

                {/*<TrackVisibility onVisible="fadeIn">
                <VideoContent isIntro={true} iam="intro" />
                </TrackVisibility>*/}

               {/* <LazyLoad height={480} offset={0} >*/}
                <VideoContent isIntro={true} />
               {/* </LazyLoad>*/}


                <LazyLoad height={480} offset={-100} >
                    <AboutContent />
                </LazyLoad>

                <div id="stories-anchor" height={480} offset={-100}>
                 <VideoContent isIntro={false} />
                </div>


                <LazyLoad height={480} offset={-100} >
                    <TestimonialContent />
                </LazyLoad>

            </div>
        </Layout>
    )
}

export default IndexPage;
