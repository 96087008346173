import React from 'react';

import {graphql, useStaticQuery} from "gatsby";
import YoutubeEmbed from "./YoutubeEmbed";


const VideoContent = ({isIntro}) => {

    const {allContentfulVideoTeaser} = useStaticQuery(
        graphql`
          query {
            allContentfulVideoTeaser(limit: 10) {
                edges {
                    node {
                      order
                      id
                      title
                      videoEmbed {
                        videoEmbed
                      }
                      body {
                        body
                      }
                      isIntro
                      embedId
                    }
                  }
            }
          }
        `
    )

    const VideoQuery = allContentfulVideoTeaser.edges;
    const videoteasersOrdered = [].concat(VideoQuery)
        .sort((a, b) => a.node.order - b.node.order)
        .map(function ({node}, i) {
            if (!node.isIntro)
                return (
                    <YoutubeEmbed key={i} index={i} title={node.title} body={node.body.body} embedId={node.embedId} isIntro={false}/>
                )
            else
                return null
        });

    if (isIntro) {
        return (
            <section className="section trailer-intro">
                <div className="main-video-container">
                    {VideoQuery.map(function ({node: post}, index) {
                        // careful difference between props.isIntro and post.isIntro
                        if (post.isIntro)
                            return (
                                <YoutubeEmbed key={index} index={index} title={post.title} body={post.body.body} embedId={post.embedId} isIntro={true}/>
                            );
                        else
                            return null
                    })}
                </div>
            </section>
        )
    } else {
        return (

            <section className="section video-teasers">
                <h2 className="subtitle">Here are some samples of Khetha content</h2>
                <div className="columns is-multiline">
                    {videoteasersOrdered}
                </div>
            </section>

        )
    }


}


export default VideoContent;
