import React from "react"
import VideoTextToggle from "./VideoTextToggle";
import styles from './YoutubeEmbed.module.css'


class YoutubeEmbed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        };
    }

    componentDidMount() {
        const youtube = document.querySelector("div[data-embed='" + this.props.embedId + "']");
        const source = "https://img.youtube.com/vi/" + youtube.dataset.embed + "/sddefault.jpg";

        const image = new Image();

        image.setAttribute("data-src", source);
        image.setAttribute("class", "lazyload");
        image.src = source;

        image.addEventListener("load", function () {
            youtube.appendChild(image);
        }(this.props.index));

        youtube.addEventListener("click", function () {
            const iframe = document.createElement("iframe");
            iframe.setAttribute("frameborder", "0");
            iframe.setAttribute("allowfullscreen", "");
            iframe.setAttribute("allow", "autoplay");
            iframe.setAttribute("src", "https://www.youtube.com/embed/" + this.dataset.embed + "?rel=0&showinfo=0&autoplay=1");
            this.innerHTML = '<div class="wait-video"><div class="wait-logo" id="wait-'+this.dataset.embed+'" ></div><div class="wait-note">loading video</div></div>';
            this.appendChild(iframe);
        });

    }

    render() {
        if (!this.props.isIntro)
            return (
                <div className="column is-half-tablet">
                    <div className="video-header-container">
                        <h3>{this.props.title}</h3>
                        <VideoTextToggle content={this.props.body}/>
                    </div>
                    <div className="video-container">
                        <div className={styles.youtube} data-name="youtube" data-embed={this.props.embedId} >
                            <div className={styles.playButton} data-class="play-button" />

                        </div>
                    </div>
                </div>
            )
        else
            return (
                <div className="video">
                    <div className={styles.youtube} data-name="youtube" data-embed={this.props.embedId}>

                        <div className={styles.playButton} data-class="play-button" />

                    </div>
                </div>
            )
    }
}

export default YoutubeEmbed
