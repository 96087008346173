import React from 'react';

import Testimonialimage1 from '../images/testimonial-pic-1.jpg';
import Testimonialimage2 from '../images/testimonial-pic-2.jpg';
import {graphql, useStaticQuery} from "gatsby";

const TestimonialContent = () => {

    const {allContentfulTestimonials} = useStaticQuery(
        graphql`
          query {
            allContentfulTestimonials(limit: 1000) {
                edges {
                  node {
                    title
                    quote {
                      quote
                    }
                  }
                }
            }
          }
        `
    )

    const testimonialQuery = allContentfulTestimonials.edges;
    const testimonialsOrdered = testimonialQuery.sort(function(a, b) {
        return a.node.title > b.node.title ? 1 : a.node.title ? -1 : 0;
    });
    const testimonialQuote1 = testimonialsOrdered[0].node.quote.quote;
    const testimonialQuote2 = testimonialsOrdered[1].node.quote.quote;
    return (
        <section className="section testimonials">
            <h2 className="title">Testimonials</h2>

            <div className="columns is-mobile">
                <div className="column is-2">
                    <img src={Testimonialimage1} alt="Testimonial 1"  className="testimonial-pic" />
                </div>

                <div className="column speech-bubble-container">
                    <div className="speech-bubble">
                        <div className="speech-point speech-point-green">&nbsp;</div>
                        <div className="speech-bubble-bg speech-bubble-bg-green">{testimonialQuote1}</div>
                    </div>
                </div>
            </div>

            <div className="columns is-mobile">
                <div className="column speech-bubble-container">
                    <div className="speech-bubble">
                        <div className="speech-point speech-point-yellow">&nbsp;</div>
                        <div className="speech-bubble-bg speech-bubble-bg-yellow">{testimonialQuote2}</div>
                    </div>
                </div>
                <div className="column is-2">
                    <img src={Testimonialimage2} alt="Testimonial 1"  className="testimonial-pic" />
                </div>
            </div>
        </section>
    )


}


export default TestimonialContent;
