import React from 'react';
import {graphql, useStaticQuery} from "gatsby";
import Aboutimage from "../images/partner-cta.png";

// dealing with rich text
import {BLOCKS, MARKS} from "@contentful/rich-text-types"
import {documentToReactComponents} from "@contentful/rich-text-react-renderer"

//import ScrollableAnchor from 'react-scrollable-anchor'
import ReactMarkdown from 'react-markdown'

const Bold = ({children}) => <span className="bold">{children}</span>
const Text = ({children}) => <p className="pres">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
}

function AboutContent() {
    const {allContentfulHomePageAboutBlock} = useStaticQuery(
        graphql`
          query {
            allContentfulHomePageAboutBlock(limit: 1000) {
              edges {
                node {
                  header {
                    header
                  }
                  body {
                    json
                  }
                }
              }
            }
          }
        `
    )

    const AboutContentQuery = allContentfulHomePageAboutBlock.edges;

    return (
        <section className="section about">
            <div className='about-intro-container'>
                {AboutContentQuery.map(function ({node: post}, index) {
                    return (
                        <React.Fragment key={index}>
                            <h2 className="title">
                                <ReactMarkdown renderers={{paragraph: 'span'}} source={post.header.header}/>
                            </h2>
                            <div>
                                {documentToReactComponents(post.body.json, options)}
                            </div>
                        </React.Fragment>
                    );
                })}
                <a href="/about" className="cta-link has-text-right">Learn More</a>
            </div>

            <div className="smartphone-cta-container">
                <div className="columns">
                    <div className="column is-3">
                        <img src={Aboutimage} alt="khetha app"/>
                    </div>
                    <div className="column smartphone-cta">
                        <div>
                            <p className="pres">Interested using the Khetha platform in your setting?</p>
                            <h2 className="subtitle"><a href="/about#partner">Partner with us & try out the App</a></h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}


export default AboutContent;
