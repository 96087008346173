import React from "react"
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

class VideoTextToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shown: true,
        };

    }

    toggle() {
        this.setState({
            shown: !this.state.shown
        });
    }

    /*componentDidMount() {
        console.log("mount videotexttoggle content: ");
        console.dir(this.props.content);
    }*/

    render() {
        var shown = {
            display: this.state.shown ? "block" : "none"
        };

        var hidden = {
            display: this.state.shown ? "none" : "block"
        }

        return (
            <div className="video-text-container">
                <div className="video-header-toggler" onClick={this.toggle.bind(this)}>
                    <FaChevronDown size={30} color="white" style={shown}/>
                    <FaChevronUp size={30} color="white" style={hidden}/>
                </div>
                <p style={hidden}>{this.props.content}</p>
            </div>
        )
    }
}

export default VideoTextToggle
